import React from 'react';
import FlexibleContent from '../components/FlexibleContent';
import FlexibleHero from '../components/FlexibleHero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Page = ({ location, pageContext }) => {
  const { seo, flexibleHero, flexibleContent, hreflang } = pageContext;

  return (
    <Layout>
      <SEO pathname={location.pathname} seo={seo} hreflang={hreflang} />
      <FlexibleHero breadcrumbs={seo.breadcrumbs} components={flexibleHero} />
      <FlexibleContent prefix='page' components={flexibleContent} />
    </Layout>
  );
};

export default Page;
